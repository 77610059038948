import * as React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Button } from "@clear-treasury/design-system";
import { useApp } from "../../ctx/AppProvider";
import useQuery from "../../hooks/useQuery";
import PennyTestPaymentDetails from "./PennyTestPaymentDetails";

import {
  IsVerifiedDocument,
  GetPaymentDetailsDocument,
} from "../../graphql/gql-types";

import type {
  Client,
  GetPaymentDetailsQuery,
  GetPaymentDetailsQueryVariables,
  IsVerifiedQuery,
  IsVerifiedQueryVariables,
} from "../../graphql/gql-types";

type WelcomeScreenProps = { currency: string };

const WelcomeScreen = ({ currency }: WelcomeScreenProps) => {
  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const router = useRouter();

  const { data: isVerified, isLoading: isVerifiedIsLoading } = useQuery<
    IsVerifiedQuery["isVerified"],
    IsVerifiedQuery,
    IsVerifiedQueryVariables
  >(activeClient ? IsVerifiedDocument : null, {
    client_ref: activeClient && activeClient.cli_reference,
  });

  const { data: paymentDetails, isLoading: paymentDetailsAreLoading } =
    useQuery<
      GetPaymentDetailsQuery["getPaymentDetails"],
      GetPaymentDetailsQuery,
      GetPaymentDetailsQueryVariables
    >(activeClient ? GetPaymentDetailsDocument : null, {
      client_ref: activeClient && activeClient.cli_reference,
      client_currency: currency,
    });

  const accountDetails = paymentDetails?.length && paymentDetails[0];

  return paymentDetailsAreLoading && isVerifiedIsLoading ? (
    <div className="flex w-full h-full items-center justify-center">
      Loading ...
    </div>
  ) : (
    <div className="flex flex-col items-center bg-white">
      <div className="mt-16 mb-12">
        <img className="w-20" src="/next/assets/welcome_icon_outline.svg" />
      </div>

      <div className="mb-4 text-xs text-teal-400 uppercase">
        Welcome {activeClient?.ctc_first_name}
      </div>

      <div className="text-5xl font-serif font-light text-gray-700 mb-4">
        Make your first transfer
      </div>

      {activeClient.is_EEA && !isVerified ? (
        <>
          <div
            className="font-normal text-base text-center mb-6 w-3/5"
            onClick={() => router.push("/transfer")}
          >
            Before you get started, we require you to complete a penny test to
            activate your account. This will help us verify your identity and
            protect your funds.
          </div>

          <PennyTestPaymentDetails
            accountName={accountDetails?.account_name}
            iban={accountDetails?.iban}
            swift={accountDetails?.swift_bic}
            currency={currency}
          />

          <div className="grid gap-4 grid-cols-1 mt-5">
            <Button href="/help" as={Link} emphasis="secondary" inverted>
              <a style={{ color: "rgba(54, 54, 54, 1)" }}>Learn more</a>
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="font-normal text-base mb-6">
            Move money from one currency to another quickly and easily
          </div>

          <div className="grid gap-4 grid-cols-2">
            <Button href="/help" as={Link} emphasis="secondary" inverted>
              <a style={{ color: "rgba(54, 54, 54, 1)" }}>Learn more</a>
            </Button>
            <Button href="/transfer" as={Link}>
              <a>Get started</a>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default WelcomeScreen;
