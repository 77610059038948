import * as React from "react";
import {
  CheckIcon,
  ExclamationCircleIcon,
  LinkIcon,
  XIcon,
} from "@heroicons/react/outline";
import ResponsiveFlag from "../ResponsiveFlag/ResponsiveFlag";
import StatusPill from "../status-pill/StatusPill";
import Utils from "../../core/Utils";
import { Payment } from "../../graphql/gql-types";

type PaymentRowProps = Payment & {
  client_needs_approval: boolean;
  approvePaymentHandler: (paymentRef: string) => void;
  approvalIsLoading: boolean;
  denyPaymentHandler: (paymentRef: string) => void;
  denialIsLoading: boolean;
  beneficiaryRequiresApproval: boolean;
};

const PaymentRow: React.FC<PaymentRowProps> = ({
  amount,
  currency,
  reference,
  beneficiary,
  requires_approval,
  client_needs_approval,
  approvePaymentHandler,
  approvalIsLoading,
  beneficiaryRequiresApproval,
  denyPaymentHandler,
  denialIsLoading,
  exported,
}) => {
  return (
    <div
      data-testid="payment-row"
      className="mb-2 select-none border-b bg-theme-color-background px-2 py-1 sm:px-5 sm:py-4 flex items-center justify-items-start"
    >
      <div className="mr-4 flex flex-col items-center">
        <ResponsiveFlag country={currency.slice(0, -1).toLowerCase()} />

        <div className="text-xs text-gray-600">{currency}</div>
      </div>

      <div className="flex flex-col h-full ml-5">
        <div className="text-lg leading-6 sm:leading-7 sm:text-xl md:text-2xl lg:text-3xl text-gray-600 md:mb-2">
          {Utils.parseAmountByCurrencyCode(amount, currency)}
        </div>

        <div className="text-xs md:text-sm text-gray-500">
          Sent to {beneficiary.account_name}
        </div>
      </div>

      <div className="ml-auto flex items-center relative">
        {requires_approval && !client_needs_approval && (
          <ExclamationCircleIcon
            data-testid="requires-approval-warning"
            className="text-red-600 inline h-12 2xl:h-7"
          />
        )}
        <div className="text-gray-400 text-xs sm:text-sm mr-2">
          <LinkIcon height={18} className="mr-1 inline" />
          {reference}
        </div>

        <StatusPill responsive status={exported ? "success" : "pending"}>
          {exported ? "Success" : "Pending"}
        </StatusPill>
        {requires_approval && !client_needs_approval && (
          <>
            <button
              className="flex items-center justify-center h-6 w-12 2xl:h-7 2xl:w-7 bg-green-600 rounded-full hover:bg-green-800 cursor-pointer transition ease-in-out delay-60 mr-3 disabled:cursor-wait"
              onClick={() => approvePaymentHandler(reference)}
              disabled={approvalIsLoading}
              data-testid="approve-payment-button"
            >
              <CheckIcon className="text-white inline h-4 2xl:h-7 2xl:p-1" />
            </button>
            <button
              className="flex items-center justify-center h-6 w-12 2xl:h-7 2xl:w-7 bg-red-700 rounded-full hover:bg-red-800 cursor-pointer transition ease-in-out delay-60 disabled:cursor-wait"
              data-testid="decline-payment-button"
              onClick={() => denyPaymentHandler(reference)}
              disabled={denialIsLoading}
            >
              <XIcon className="text-white inline h-4 2xl:h-7 2xl:p-1" />
            </button>
          </>
        )}
        {beneficiaryRequiresApproval && (
          <div className="absolute -bottom-5 text-red-600 text-sm w-screen">
            The payment requires you to approve the beneficiary first
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentRow;
