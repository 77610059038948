import React from "react";
import { FC } from "react";
import Utils from "../../core/Utils";
import Flag, { FLAG_SIZE } from "../Flag/Flag";

export interface IBalanceCard {
  balance: number;
  onClick?: () => void;
  currency: string;
  isDisabled?: boolean;
  isisLoading?: boolean;
}

const BalanceCard: FC<IBalanceCard> = ({
  balance,
  currency,
  onClick,
  isisLoading,
  isDisabled = false,
}) => {
  return (
    <a
      onClick={() => {
        if (!isDisabled && onClick) onClick();
      }}
      data-testid="balance-item"
      aria-disabled={isDisabled}
      style={isisLoading ? { cursor: "progress" } : {}}
      className={`${
        isDisabled ? "cursor-not-allowed" : "cursor-pointer"
      } bg-gray-100 hover:bg-gray-200 p-2.5 flex items-center w-64 min-w-64`}
    >
      <div className="flex flex-col mr-4">
        <Flag
          size={FLAG_SIZE.XL}
          country={currency.slice(0, -1).toLowerCase()}
        />
        <span className="text-xs pt-1 text-center text-gray-800">
          {currency}
        </span>
      </div>

      <span className="text-xl lg:text-xl w-full text-navy-600">
        {Utils.parseAmountByCurrencyCode(balance, currency)}
      </span>
    </a>
  );
};

export default BalanceCard;
