import * as React from "react";
import { useEffect, useState } from "react";
import Link from "next/link";
import { Button } from "@clear-treasury/design-system";
import { ArrowRightIcon } from "@heroicons/react/solid";
import TransactionRow from "../TransactionRow/TransactionRow";
import Toggle from "../toggle/Toggle";

import type { Trade } from "../../graphql/gql-types";

export type RecentTransactionProps = {
  data: Trade[];
  hasMoreTrades?: boolean;
};

const RecentTransactions: React.FC<RecentTransactionProps> = ({
  data,
  hasMoreTrades,
}) => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [transactions, setTransactions] = useState<Trade[]>([]);
  const shouldShowToggleButton = data.some((item) => item.status === "Pending");

  useEffect(() => {
    const pendingItems = data.filter((item) => item.status === "Pending");

    setTransactions(toggle ? pendingItems : data);
  }, [toggle, data]);

  return (
    <>
      <div className="flex justify-between pb-6">
        <h3 className="text-teal-700 text-2xl">Recent transactions</h3>
        {shouldShowToggleButton && (
          <div data-testid="filter" className="flex items-center">
            <span className="text-teal-700">All</span>
            <Toggle
              checked={toggle}
              onChange={() => setToggle(!toggle)}
              id="transaction-statuses"
              className="transform scale-75" // TODO: bit of a hack to make the toggle smaller. Need to provide a `size` prop instead.
            />
            <span className="text-teal-700">Pending</span>
          </div>
        )}
      </div>

      {transactions?.map((transaction) => (
        <TransactionRow key={transaction.id} {...transaction} />
      ))}

      {hasMoreTrades && (
        <div className="ml-auto mt-8">
          <Button
            as={Link}
            size={Button.Size.LARGE}
            emphasis={Button.Emphasis.TRANSPARENT}
            href="/transactions"
          >
            <span>
              View all transactions
              <ArrowRightIcon width="16" />
            </span>
          </Button>
        </div>
      )}
    </>
  );
};

export default RecentTransactions;
