import { useEffect } from "react";

const useDOMEvent = (
  selector: string,
  type: string,
  callback: (ev: any) => void,
  options = {},
  dependencyArray: Array<any> = []
): void => {
  useEffect(() => {
    const $element = document.querySelector(selector);
    $element?.addEventListener(type, callback, options);

    return () => $element?.removeEventListener(type, callback, options);
  }, dependencyArray);
};

export default useDOMEvent;
