import * as React from "react";
import { FC, useCallback, useState } from "react";
import { useRouter } from "next/router";
import { Button } from "@clear-treasury/design-system";
import MoneyInput from "../money-input/MoneyInput";
import { AMOUNT_TYPES, defaultTransferValues } from "../quote-form/QuoteForm";
import currencies from "../../data/currencies.json";
import Utils from "../../core/Utils";
import { useApp } from "../../ctx/AppProvider";
import { Client } from "../../graphql/gql-types";

export interface IFastQuoteFormProps {}

export interface IAmountType {
  amount?: string;
  currency: string;
}

export interface IFastQuoteFormAmountProps {
  sell: IAmountType;
  buy: IAmountType;
  amountType: AMOUNT_TYPES;
  shouldGetQuotes: boolean;
}

// TODO Remove it! It is used in more ona places
// @see: src/components/quote-form/QuoteForm.tsx:66

const FastQuoteForm: FC<IFastQuoteFormProps> = () => {
  const router = useRouter();

  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const currencyList = currencies[
    activeClient.cty_value === "PRIVATE" ? "privateClient" : "corporateClient"
  ].map(({ CurrencyCode }) => CurrencyCode);

  const [amount, setAmount] = useState<IFastQuoteFormAmountProps>({
    amountType: null, //AMOUNT_TYPES.BUY,
    shouldGetQuotes: false,
    buy: { currency: defaultTransferValues.buy.currency, amount: "" },
    sell: { currency: defaultTransferValues.sell.currency, amount: "" },
  });

  // TODO move it to place where it can be reusable many times!
  //@see: src/components/quote-form/QuoteForm.tsx:335
  const onChangeInputAmount = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const onAmountChange = (amountType: AMOUNT_TYPES) => (ev) => {
    const typeOfTransfer = AMOUNT_TYPES[amountType].toLowerCase();
    const typeOfReset = typeOfTransfer === "sell" ? "buy" : "sell";
    setAmount((prevState) => ({
      ...prevState,
      shouldGetQuotes: ev.target.value.length > 0,
      [typeOfReset]: {
        currency: prevState[typeOfReset].currency,
        amount: "",
      },
      [typeOfTransfer]: {
        currency: prevState[typeOfTransfer].currency,
        amount: ev.target.value,
      },
    }));
  };

  const onGetQuotes = useCallback(() => {
    const typeOfTransfer = AMOUNT_TYPES[amount.amountType].toLowerCase();
    const queryParams = {
      sourceCurrency: amount.sell.currency,
      targetCurrency: amount.buy.currency,
      amountValue: amount[typeOfTransfer].amount,
      amountType: amount.amountType,
    };

    router.push({
      pathname: "/transfer",
      query: queryParams,
    });
  }, [amount, amount.amountType]);

  return (
    <>
      <div className="pb-6 text-teal-700 text-2xl">Make a transfer</div>
      <div className="flex flex-col">
        <MoneyInput
          shadedOut={
            Utils.isNotEmpty(amount.amountType) &&
            amount.amountType !== AMOUNT_TYPES.SELL
          }
          name="sell"
          label="I have (sell)"
          currencies={currencyList}
          onKeyPress={onChangeInputAmount}
          onAmountChange={onAmountChange(AMOUNT_TYPES.SELL)}
          amount={amount.sell.amount}
          currency={amount.sell.currency}
          onfocus={() =>
            setAmount({
              ...amount,
              amountType: AMOUNT_TYPES.SELL,
              buy: { currency: amount.buy.currency, amount: "" },
            })
          }
          onCurrencyChange={(currencyValue) => {
            const currencyName = currencyValue.selectedItem.value;
            setAmount({
              ...amount,
              buy: {
                ...amount.buy,
                currency:
                  currencyName === amount.buy.currency
                    ? amount.sell.currency
                    : amount.buy.currency,
              },
              sell: {
                ...amount.sell,
                currency: currencyName,
              },
            });
          }}
        />

        <MoneyInput
          name="buy"
          label="I want (buy)"
          shadedOut={
            Utils.isNotEmpty(amount.amountType) &&
            amount.amountType !== AMOUNT_TYPES.BUY
          }
          currencies={currencyList}
          onKeyPress={onChangeInputAmount}
          onAmountChange={onAmountChange(AMOUNT_TYPES.BUY)}
          onfocus={() =>
            setAmount({
              ...amount,
              amountType: AMOUNT_TYPES.BUY,
              sell: {
                currency: amount.sell.currency,
                amount: "",
              },
            })
          }
          amount={amount.buy.amount}
          currency={amount.buy.currency}
          onCurrencyChange={(currencyValue) => {
            const currencyName = currencyValue.selectedItem.value;
            setAmount({
              ...amount,
              buy: {
                ...amount.buy,
                currency: currencyName,
              },
              sell: {
                ...amount.sell,
                currency:
                  currencyName === amount.sell.currency
                    ? amount.buy.currency
                    : amount.sell.currency,
              },
            });
          }}
        />
        <Button
          emphasis={amount.shouldGetQuotes ? "primary" : "secondary"}
          disabled={!amount.shouldGetQuotes}
          onClick={onGetQuotes}
          size={Button.Size.LARGE}
        >
          Get a quote
        </Button>
      </div>
    </>
  );
};

export default FastQuoteForm;
