type PaymentDetailsProps = {
  accountName: string;
  iban: string;
  swift: string;
  currency: string;
};

const PennyTestPaymentDetails = ({
  accountName,
  iban,
  swift,
  currency,
}: PaymentDetailsProps) => {
  return (
    <div className="rounded-md px-4 py-6 border border-theme-color-primary text-theme-color-primary bg-theme-color-primary-background">
      <h1 className="text-center mb-6 text-2xl font-medium">Payment Details</h1>
      <span>
        Complete the penny test by making a payment of{" "}
        <b>at least 0.01 {currency}</b> to:
      </span>
      <div className="flex justify-between px-3 mt-5">
        <div className="flex-col">
          <div className="text-base font-semibold mt-3">Account Name:</div>
          <div className="text-base font-semibold mt-3">IBAN:</div>
          <div className="text-base font-semibold mt-3">SWIFT BIC:</div>
        </div>
        <div className="flex-col">
          <div className="text-base font-semibold mt-3">{accountName}</div>
          <div className="text-base font-semibold mt-3">{iban}</div>
          <div className="text-base font-semibold mt-3">{swift}</div>
        </div>
      </div>
    </div>
  );
};

export default PennyTestPaymentDetails;
