import React, { FC, useEffect, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

export interface LegStatusProps {
  title: string;
  subTitle?: string;
  fields: Record<string, string>;
  collapsible?: boolean;
  width?: string;
}

const LegStatus: FC<LegStatusProps> = ({
  title,
  subTitle,
  collapsible = false,
  width = "w-full",
  fields,
}) => {
  const [collapse, toggleCollapse] = useState<boolean>(collapsible);
  const [data, setData] = useState<Record<string, string>[]>([]);

  const capitalizeFirstLetter = (string: string) => {
    if (string === "iban") return "IBAN";
    if (string === "swift code") return "SWIFT CODE";

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const keys: Record<string, string>[] = Object.keys(fields).map((item) => {
      const value = fields[item];

      let key = item.replace(/_/, " ");
      key = capitalizeFirstLetter(key);

      return { key, value };
    });

    setData(keys);
  }, [fields]);

  return (
    <div data-testid="leg-status" className={`${width}`}>
      <div
        data-testid="leg-heading"
        onClick={() => toggleCollapse(!collapse)}
        className="flex items-center mb-2 cursor-pointer"
      >
        <span className="block rounded-full w-3 h-3 mr-2 mt-1 bg-yellow-600" />
        <span className="text-xs sm:text-sm md:text-base text-yellow-600 mr-3">
          {title}
        </span>
        <span className="block self-center">
          {collapse && <ChevronUpIcon height={16} className="text-gray-600" />}
          {!collapse && (
            <ChevronDownIcon height={16} className="text-gray-600" />
          )}
        </span>
      </div>
      <div
        data-testid="leg-body"
        className={`${
          collapse ? "max-h-256" : "max-h-0"
        } flex flex-col overflow-y-auto transition-max-height duration-500 ease-in-out bg-gray-100`}
      >
        {subTitle && (
          <h3 className="text-xs sm:text-sm md:text-base text-gray-500 pb-2">
            {subTitle}
          </h3>
        )}
        <div className="mb-2">
          {data.map(({ key, value }) => (
            <div
              key={`${key}-${value}`}
              data-testid="leg-field"
              className="flex py-1 md:py-2"
            >
              <span className="text-xs sm:text-sm md:text-base text-teal-700 flex-1 md:flex-none w-1/4 block">
                {key}:
              </span>
              <span className="text-xs sm:text-sm md:text-base text-gray-600 flex-1 md:flex-none w-3/4 block">
                {value?.split(",").map((item, i) => (
                  <span key={item + i} className="block">
                    {item}
                    {i < value.split(",").length - 1 ? "," : ""}
                  </span>
                ))}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LegStatus;
