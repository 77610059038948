import classNames from "classnames";
import React from "react";

export interface StatusPillProps {
  status: "active" | "pending" | "success" | "failed" | "custom";
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  responsive?: boolean;
}

const StatusPill: React.FC<StatusPillProps> = ({
  children,
  status,
  className,
  responsive,
}) => {
  const color =
    {
      pending: "text-yellow-600 bg-yellow-100",
      active: "text-green-600 bg-green-100",
      success: "text-green-600 bg-green-100",
      failed: "text-red-600 bg-red-100",
      custom: "",
    }[status?.toLowerCase()] || "";

  let baseClasses = `relative inline rounded-full text-xs ${color}`;

  if (responsive) {
    baseClasses += " py-1 px-2 sm:py-2 sm:px-4 sm:mx-4";
  } else {
    baseClasses += " py-2 px-4";
  }

  const classes = classNames(baseClasses, className);

  return <div className={classes}>{children}</div>;
};

export default StatusPill;
