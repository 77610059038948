import React, { FC } from "react";

export interface IHeading {
  title: string;
  subTitle?: string;
  align?: "right" | "left";
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const Heading: FC<IHeading> = ({
  title,
  subTitle,
  align = "left",
  as = "h1",
}) => {
  const css = align === "right" ? "md:text-right" : "md:text-left";
  const As = as;
  let titleClass = "";

  switch (as) {
    case "h1":
      titleClass = "text-2xl md:text-3xl lg:text-4xl";
      break;

    case "h2":
      titleClass = "text-xl md:text-2xl lg:text-3xl";
      break;

    case "h3":
      titleClass = "text-lg md:text-1xl lg:text-2xl";
      break;

    case "h4":
      titleClass = "text-base md:text-xl lg:text-1xl";
      break;

    case "h5":
      titleClass = "text-sm md:text-lg lg:text-xl";
      break;

    case "h6":
      titleClass = "text-xs md:text-base lg:text-lg";
      break;

    default:
      break;
  }

  return (
    <div className={`m-0 text-center md:text-left flex-grow ${css}`}>
      <As className={`${titleClass} text-teal-700`}>{title}</As>
      {subTitle && (
        <p className={`text-sm md:text-base lg:text-lg text-gray-600`}>
          {subTitle}
        </p>
      )}
    </div>
  );
};

export default Heading;
