import * as React from "react";
import classNames from "classnames";

// TODO: Make this the bundled flags import instead of in index.tsx
// Importing css here breaks nextjs apps: https://nextjs.org/docs/messages/css-npm
// import "flag-icon-css/css/flag-icon.css";

export enum FLAG_SIZE {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  XL = "xl",
}

export type FlagProps = {
  country: string;
  size?: FLAG_SIZE;
  rounded?: string;
};

const Flag = ({
  country,
  rounded,
  size = FLAG_SIZE.SMALL,
}: FlagProps): JSX.Element => {
  const baseClasses = `
    flag-icon-${country} pl-6 bg-left bg-contain bg-no-repeat inline-block ${
    rounded ? rounded : ""
  }`.trim();

  const flagClasses = classNames(baseClasses, {
    "w-4 h-4": size === FLAG_SIZE.SMALL,
    "w-8 h-8": size === FLAG_SIZE.MEDIUM,
    "w-14 h-12": size === FLAG_SIZE.LARGE,
    "w-16 h-11": size === FLAG_SIZE.XL,
  });

  return <span className={flagClasses} />;
};

export default Flag;
