import React from "react";
import { Status } from "../TransactionRow/transactionStatus";

export type TransactionStepProps = {
  message: string;
  status?: Status;
};

const TransactionStep: React.FC<TransactionStepProps> = ({
  message,
  status,
}) => {
  const statusStyles = {
    PENDING: {
      dot: "rounded-full w-3 h-3 mr-2 mt-1 bg-yellow-600",
      text: "text-xs sm:text-sm md:text-base text-yellow-600",
    },
    FAILED: {
      dot: "rounded-full w-3 h-3 mr-2 mt-1 bg-red-700",
      text: "text-xs sm:text-sm md:text-base text-red-700",
    },
    SUCCESS: {
      dot: "rounded-full w-3 h-3 mr-2 mt-1 bg-green-700",
      text: "text-xs sm:text-sm md:text-base text-green-700",
    },
    IDLE: {
      dot: "rounded-full w-3 h-3 mr-2 mt-1 bg-gray-400",
      text: "text-xs sm:text-sm md:text-base text-gray-400",
    },
  };
  const currentStatus =
    statusStyles[status?.toUpperCase()] || statusStyles.IDLE;

  // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  return (
    <div data-testid="transaction-step" className="flex items-center mb-2">
      <div className={currentStatus.dot} />
      <div className={currentStatus.text}>{message}</div>
    </div>
  );
};

export default TransactionStep;
