import * as React from "react";
import { useRouter } from "next/router";
import { format, parseISO } from "date-fns";
import { Button } from "@clear-treasury/design-system";
import {
  ArrowCircleUpIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  LinkIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import ResponsiveFlag from "../ResponsiveFlag/ResponsiveFlag";
import StatusPill, { StatusPillProps } from "../status-pill/StatusPill";
import TransactionStep from "../TransactionStep/TransactionStep";
import PaymentRow from "../PaymentRow/PaymentRow";
import LegStatus from "../LegStatus/LegStatus";
import { composeStatuses, Status } from "./transactionStatus";
import { useMutation } from "../../hooks/useMutation";
import { useApp } from "../../ctx/AppProvider";
import { TOAST_STATUSES, useToast } from "../../ctx/ToastProvider";
import {
  ApprovePaymentDocument,
  ApprovePaymentMutationVariables,
  Client,
  DenyPaymentDocument,
  DenyPaymentMutationVariables,
  PaymentApprovalResult,
  Trade,
  TradeStatus,
} from "../../graphql/gql-types";

const formatAmount = (amount: number, locale?: string) => {
  return (
    amount &&
    amount.toLocaleString(locale || "en-GB", { minimumFractionDigits: 2 })
  );
};

const TransactionRow: React.FC<Trade> = ({
  bought_currency,
  bought_amount,
  sold_currency,
  sold_amount,
  reference,
  value_date,
  quote_rate,
  status,
  converted,
  funds_received,
  settled,
  payment_details = {},
  payments,
  outstanding_amount,
  id,
}) => {
  const [activeClient] = useApp<Client>((store) => store.activeClient);
  const router = useRouter();
  const toast = useToast();

  const [expanded, setExpanded] = React.useState<boolean>();
  const [approvePaymentWithReference, setApprovePaymentWithReference] =
    React.useState<string>();
  const [denyPaymentWithReference, setDenyPaymentWithReference] =
    React.useState<string>();
  const [
    beneficiaryRequiresApprovalFirst,
    setBeneficiaryRequiresApprovalFirst,
  ] = React.useState<boolean>();

  const {
    funding_status,
    funding_status_message,
    instruct_payment_status,
    instruct_payment_status_message,
    settlement_status,
    settlement_status_message,
    outbound_payment_status,
    outbound_payment_status_message,
  } = composeStatuses({
    id,
    reference,
    value_date,
    quote_rate,
    payments,
    status,
    settled,
    outstanding_amount,
    funds_received,
    converted,
    bought_amount,
    bought_currency,
    sold_currency,
    sold_amount,
    trade_date: null,
    funds_outstanding: null,
  });

  const { isLoading: paymentIsLoading } = useMutation<
    PaymentApprovalResult,
    ApprovePaymentMutationVariables["input"]
  >(
    approvePaymentWithReference ? ApprovePaymentDocument : null,
    {
      client_ref: activeClient.cli_reference,
      payment_ref: approvePaymentWithReference,
    },
    () => {
      toast.notify({
        message: "You have successfully approved the payment.",
        status: TOAST_STATUSES.POSITIVE,
      });
      setApprovePaymentWithReference("");
    },
    (result) => {
      if (result.data.approvePayment.message === "approve beneficiary first") {
        setBeneficiaryRequiresApprovalFirst(true);
      } else {
        toast.notify({
          message: "Failed to approve the payment. Please contact us",
          status: TOAST_STATUSES.CRITICAL,
        });
      }
      setApprovePaymentWithReference("");
    }
  );

  const { isLoading: denialIsLoading } = useMutation<
    PaymentApprovalResult,
    DenyPaymentMutationVariables["input"]
  >(
    denyPaymentWithReference ? DenyPaymentDocument : null,
    {
      client_ref: activeClient.cli_reference,
      payment_ref: denyPaymentWithReference,
    },
    () => {
      toast.notify({
        message: "You have successfully denied the payment.",
        status: TOAST_STATUSES.POSITIVE,
      });
      setDenyPaymentWithReference("");
    },
    () => {
      toast.notify({
        message: "Failed to deny the payment. Please contact us",
        status: TOAST_STATUSES.CRITICAL,
      });
      setDenyPaymentWithReference("");
    }
  );

  return (
    <div data-testid="transaction-item" className="mb-2 select-none">
      <div
        className="bg-theme-color-background px-4 py-4 cursor-pointer border-b"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex flex-col xs:flex-row justify-between">
          <div className="flex items-center">
            <div className="mr-4 flex flex-col items-center">
              <ResponsiveFlag
                country={bought_currency.slice(0, -1).toLowerCase()}
              />

              <div className="text-xs text-gray-800">{bought_currency}</div>
            </div>

            <div className="flex justify-between flex-row-reverse w-full xs:w-auto xs:flex-row xs:justify-self-start">
              <div className="flex flex-col items-center h-full ml-auto xs:ml-0">
                <ArrowCircleUpIcon
                  strokeWidth={1}
                  className="w-6 h-6 md:w-8 md:h-8 lg:w-12 lg:h-12 transform rotate-45 text-gray-400"
                />
                <div className="text-xs md:text-sm text-gray-400">OUT</div>
              </div>

              <div className="flex flex-col h-full xs:ml-5">
                <div
                  data-testid="bought-amount"
                  className="leading-6 sm:leading-7 text-lg md:text-2xl lg:text-3xl text-gray-600 lg:mb-2"
                >
                  {formatAmount(bought_amount)}
                </div>

                <div
                  data-testid="sell-amount"
                  className="text-base text-gray-500"
                >
                  {formatAmount(sold_amount)} {sold_currency}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-2 xs:mt-0 space-x-4">
            {payments.some((payment) => payment.requires_approval) &&
              !activeClient.needs_approval && (
                <ExclamationCircleIcon
                  data-testid="requires-approval-warning"
                  height={30}
                  className="text-red-600 inline"
                />
              )}

            <div className="flex flex-col sm:flex-row space-x-2">
              <div className="text-gray-500 text-xs md:text-sm mr-2">
                <LinkIcon height={18} className="mr-1 inline" />
                {reference}
              </div>
              <div className="text-gray-500 text-xs md:text-sm mr-2">
                <CalendarIcon height={18} className="mr-1 inline" />
                {format(parseISO(value_date), "dd MMM yyyy")}
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <StatusPill
                responsive
                status={status.toLowerCase() as StatusPillProps["status"]}
              >
                {status}
              </StatusPill>

              {expanded ? (
                <ChevronUpIcon height={20} className="text-gray-600" />
              ) : (
                <ChevronDownIcon height={20} className="text-gray-600" />
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${
          expanded ? "" : "max-h-0"
        } overflow-y-auto transition-max-height duration-500 ease-in-out bg-gray-100`}
      >
        <div className="flex flex-wrap px-5 pt-4 pb-2">
          <div
            data-testid="trade-status"
            className="flex flex-col flex-grow md:flex-1"
          >
            {funding_status === Status.PENDING && (
              <LegStatus
                title={funding_status_message}
                subTitle="Fund your transfer by making a payment to Clear Treasury using the following details:"
                fields={{ ...payment_details, "Payment reference": reference }}
              />
            )}
            {funding_status !== Status.PENDING && (
              <TransactionStep
                status={funding_status}
                message={funding_status_message}
              />
            )}
            <TransactionStep
              status={instruct_payment_status}
              message={instruct_payment_status_message}
            />
            <TransactionStep
              status={settlement_status}
              message={settlement_status_message}
            />
            {payments.some((payment) => payment.requires_approval) &&
            activeClient.needs_approval ? (
              <TransactionStep
                status={settlement_status}
                message={"Payment awaiting approval"}
              />
            ) : (
              <TransactionStep
                status={outbound_payment_status}
                message={outbound_payment_status_message}
              />
            )}
          </div>
          {status !== ("Failed" as TradeStatus) && outstanding_amount > 0 && (
            <div className="flex-grow md:flex-none flex justify-center lg:justify-end items-center">
              <Button
                emphasis={"primary"}
                onClick={() =>
                  router.push({
                    pathname: "/transfer",
                    query: {
                      instructPaymentTradeID: id,
                      balance_currency: bought_currency,
                    },
                  })
                }
                disabled={!activeClient.allowed_to_instruct_payments}
              >
                Instruct a payment
              </Button>
            </div>
          )}
        </div>

        {!!payments.length && (
          <div className="w-full sm:w-5/6 px-5 pb-4">
            {payments.map((payment) => (
              <div key={payment.reference}>
                <PaymentRow
                  key={payment.reference}
                  client_needs_approval={activeClient.needs_approval}
                  approvalIsLoading={paymentIsLoading}
                  approvePaymentHandler={(paymentRef: string) =>
                    setApprovePaymentWithReference(paymentRef)
                  }
                  beneficiaryRequiresApproval={beneficiaryRequiresApprovalFirst}
                  {...payment}
                  denialIsLoading={denialIsLoading}
                  denyPaymentHandler={(paymentRef: string) =>
                    setDenyPaymentWithReference(paymentRef)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionRow;
