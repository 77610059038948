import { Payment, Trade } from "src/graphql/gql-types";
import Utils from "../../core/Utils";

export enum Status {
  PENDING = "Pending",
  SUCCESS = "Success",
  FAILED = "Failed",
  IDLE = "Idle",
}

interface TradeStatuses {
  funding_status: Status;
  funding_status_message: string;
  instruct_payment_status: Status;
  instruct_payment_status_message: string;
  settlement_status: Status;
  settlement_status_message: string;
  outbound_payment_status: Status;
  outbound_payment_status_message: string;
  payments: Payment[];
}

function getSumOfAllPayments(trade: Trade): number {
  return trade.payments.reduce(
    (acc, curr) => (acc += parseFloat(curr.amount)),
    0
  );
}

function getExportedPayments(trade: Trade) {
  return trade.payments.filter((payment) => payment.exported);
}

function hasExportedPayments(trade: Trade): boolean {
  return getExportedPayments(trade).length > 0;
}

function sumExportedPayments(trade: Trade): number {
  const exportedPayments = getExportedPayments(trade);
  return exportedPayments.reduce(
    (acc, curr) => (acc += parseFloat(curr.amount)),
    0
  );
}

function hasAllPaymentsExported(trade: Trade): boolean {
  const exportedPayments = getExportedPayments(trade);
  return (
    exportedPayments.length > 0 &&
    exportedPayments.length === trade.payments.length
  );
}

function getFundingStatus(trade: Trade): Status {
  return trade.funds_received >= trade.sold_amount
    ? Status.SUCCESS
    : Status.PENDING;
}

function getFundingStatusMessage(trade: Trade): string {
  return getFundingStatus(trade) === Status.SUCCESS
    ? "Funds received"
    : trade.funds_received > 0
    ? `Partially funded. ${Utils.parseAmountByCurrencyCode(
        trade.sold_amount - trade.funds_received,
        trade.sold_currency
      )} ${trade.sold_currency} outstanding`
    : "Awaiting funds";
}

function getInstructPaymentStatus(trade: Trade): Status {
  const sumOfAllPayments = (getSumOfAllPayments(trade) * 100) / 100;
  return trade.payments.length === 0
    ? Status.PENDING
    : sumOfAllPayments >= trade.bought_amount
    ? Status.SUCCESS
    : Status.PENDING;
}

function getInstructPaymentStatusMessage(trade: Trade): string {
  const sumOfAllPayments = getSumOfAllPayments(trade);
  return getInstructPaymentStatus(trade) === Status.SUCCESS
    ? "Fully instructed"
    : trade.payments.length === 0
    ? "Awaiting instruction"
    : `${trade.payments.length} payment${
        trade.payments.length > 1 ? "s" : ""
      } instructed. ${Utils.parseAmountByCurrencyCode(
        trade.bought_amount - sumOfAllPayments,
        trade.bought_currency
      )} ${trade.bought_currency} outstanding`;
}

function getSettlementStatus(trade: Trade): Status {
  return getFundingStatus(trade) === Status.PENDING
    ? Status.IDLE
    : trade.settled
    ? Status.SUCCESS
    : Status.PENDING;
}

function getSettlementStatusMessage(trade: Trade): string {
  const settlement_status = getSettlementStatus(trade);
  return settlement_status === Status.IDLE
    ? "Internal trade settlement"
    : settlement_status === Status.SUCCESS
    ? "Internal trade settlement complete"
    : "Awaiting internal trade settlement";
}

function getOutboundPaymentStatus(trade: Trade): Status {
  const noExporedPayments =
    getExportedPayments(trade).length === trade.payments.length;
  const sumOfAllPayments = getSumOfAllPayments(trade);

  return trade.settled === false || trade.payments.length === 0
    ? Status.IDLE
    : noExporedPayments && sumOfAllPayments >= trade.bought_amount
    ? Status.SUCCESS
    : Status.PENDING;
}

function getOutboundPaymentStatusMessage(trade: Trade): string {
  const exportedPayments = getExportedPayments(trade);
  const noExporedPayments = !hasExportedPayments(trade);
  const sumOfExportedPayments = sumExportedPayments(trade);

  return getOutboundPaymentStatus(trade) === Status.IDLE
    ? "Outbound payment"
    : noExporedPayments
    ? "Awaiting outbound payment"
    : hasAllPaymentsExported(trade) &&
      sumOfExportedPayments >= trade.bought_amount
    ? "Outbound payment sent"
    : `${exportedPayments.length} payment${
        exportedPayments.length > 1 ? "s" : ""
      } sent for ${Utils.parseAmountByCurrencyCode(
        sumOfExportedPayments,
        trade.bought_currency
      )} ${trade.bought_currency}`;
}

export function composeStatuses(trade: Trade): TradeStatuses {
  return {
    funding_status: getFundingStatus(trade),
    funding_status_message: getFundingStatusMessage(trade),
    instruct_payment_status: getInstructPaymentStatus(trade),
    instruct_payment_status_message: getInstructPaymentStatusMessage(trade),
    settlement_status: getSettlementStatus(trade),
    settlement_status_message: getSettlementStatusMessage(trade),
    outbound_payment_status: getOutboundPaymentStatus(trade),
    outbound_payment_status_message: getOutboundPaymentStatusMessage(trade),
    payments: trade.payments,
  };
}
